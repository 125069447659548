import axios from '@/axios'
import { RelationSelect, DefaultInput, FormErrors } from 'apptimizm-ui'
import { Ref } from 'vue'
import { ISelectValue, Template, TemplateMeta } from '../types'
import { v4 } from 'uuid'
import { ButtonLoader } from '@/UI/loader'

interface IProps {
  templateForm: Ref<Template>
  formErrors: Ref<FormErrors>
  templateMeta: TemplateMeta
  templateChoicesOptions: Ref<ISelectValue[]>
  toggleConfirm: (type: string) => void
  selectTemplate: (v: Template|null) => void
  onDrop: (e: any, target: string) => void
  onStartDrag: (e: any, choice: ISelectValue) => void
  replaceOnDblcClick: (item: ISelectValue, target: 'selected'|'options') => void
  unusedParams: Ref<ISelectValue[]>
  selecteUnusedParam: (param: ISelectValue) => void
  isLoadingTabTemplate: boolean
}
function unusedLenght (check: Template, choice: ISelectValue) {
  return check.fields.filter((item: ISelectValue) => item.value.includes(choice.value)).length || 0
}

export default ({
  templateForm,
  formErrors,
  templateMeta,
  templateChoicesOptions,
  toggleConfirm,
  selectTemplate,
  replaceOnDblcClick,
  onStartDrag,
  onDrop,
  unusedParams,
  selecteUnusedParam,
  isLoadingTabTemplate
}: IProps) => (
  <>
    {isLoadingTabTemplate
      ? <ButtonLoader hidden={!isLoadingTabTemplate}/>
      : <>
        <div class="row row--70-30 mt-4">
          <RelationSelect
            axios={axios}
            placeholder="Шаблон по умолчанию"
            constantPlaceholder={false}
            modelValue={templateForm.value}
            endpoint={templateMeta.endpoint}
            itemConverter={(v: any) => templateMeta.load(v)}
            onValueChange={(v: any) => selectTemplate(v)}
            responseItemsKey="results"
            responseTotalKey="count"
            clearable={true}
          />
          {templateForm.value.name
            ? <button class="default-button" onClick={() => toggleConfirm('delete')}>Удалить</button>
            : <button class="default-button" onClick={() => toggleConfirm('create')}>Новый</button>
          }
        </div>
        <div class="row mt-2">
          <label>
            <DefaultInput
              class={templateForm.value.fieldSeparator ? 'input-placeholder-up' : ''}
              modelValue={templateForm.value.fieldSeparator}
              placeholder="Разделитель полей"
              errors={formErrors.value.forField('fieldSeparator')}
              onValueChange={(v: string) => { templateForm.value.fieldSeparator = v }}
            />
          </label>
          <label>
            <DefaultInput
              class={templateForm.value.decimalSeparator ? 'input-placeholder-up' : ''}
              modelValue={templateForm.value.decimalSeparator}
              placeholder="Десятичный разделитель"
              errors={formErrors.value.forField('decimalSeparator')}
              onValueChange={(v: string) => { templateForm.value.decimalSeparator = v }}
            />
          </label>
        </div>
        <div class="choices-block mt-2">
          <div class="choice-header">
            <div class="title">Неиспользуемые параметры</div>

            <div class="choice-list-unused">
              {unusedParams.value && unusedParams.value.map((choice: ISelectValue) =>
                <div
                  class="choice-item"
                  onClick={(e: Event) =>
                    unusedLenght(templateForm.value, choice) < 10
                      ? selecteUnusedParam({ name: choice.name, value: choice.value + '--' + v4() })
                      : null
                  }
                >{choice.name}
                  <span>{unusedLenght(templateForm.value, choice) ? 'x' + unusedLenght(templateForm.value, choice) : ''}
                  </span>
                </div>

              )}
            </div>
          </div>
        </div>

        <div class="choices-block loading-template mt-2">
          <div class="choice-header row--50-50">
            <div class="title">Список полей</div>
            <div class="title">Поля в файле</div>
          </div>

          <div
            class="choices-body row--50-50"
            onDragover={(e) => e.preventDefault()}
          >
            <ul class="choice-list" onDrop={(e: Event) => onDrop(e.target, 'options')}>
              {templateChoicesOptions.value && templateChoicesOptions.value.map((choice: ISelectValue) =>
                <li onDblclick={() => replaceOnDblcClick(choice, 'selected')} draggable={true} onDragstart={(e) => onStartDrag(e, choice)} class="choice-item">
                  {choice.name}
                </li>
              )}
            </ul>
            <ul class="choice-list" onDrop={(e: Event) => onDrop(e.target, 'selected')}>
              {templateForm.value && templateForm.value.fields.map((choice: ISelectValue) =>
                <li onDblclick={() => replaceOnDblcClick(choice, 'options')} draggable={true} onDragstart={(e) => onStartDrag(e, choice)} class="choice-item">
                  {choice.name}
                </li>
              )}
            </ul>
          </div>

        </div>
      </>
    }

  </>
)
