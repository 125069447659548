
import { defineComponent, onMounted, PropType, Ref, ref, useCssVars, watch } from 'vue'
import { FormErrors } from 'apptimizm-ui'
import axios from '@/axios'
import { Template, TemplateMeta, ISelectValue, TemplateExportMeta, ProjectMeta } from '../types'
import { getCeleryTask } from '../../../core/celery/index'
import { host } from '../../../const'
import { ButtonLoader } from '../../../UI/loader'
import { mutations } from '../../../store/index'
import { Modal } from './modal'
import { useRoute } from 'vue-router'
import { RadioButtonGroup } from '@/UI/radio-button-group'
import DeleteDirectoryModal from './DeleteDirectoryModal.vue'
import ProgressButton from '@/UI/ProgressButton.vue'
import { v4 as uuid } from 'uuid'

export default defineComponent({
  props: {
    templateId: {
      type: String as PropType<string|null>,
      required: true
    },
    templateExportId: {
      type: String as PropType<string|null>,
      required: true
    },
    projectId: {
      type: String as PropType<string>,
      required: true
    },
    updateTemplateInProject: {
      type: Function as PropType<(id: string|null, type: 'template'|'templateExport') => void>,
      required: true
    }
  },
  setup (props) {
    const { params } = useRoute()
    const projectMeta = new ProjectMeta()
    const templateList = ref<Template[]>([])
    const templateForm = ref<Template>(new Template())
    const templateMeta = new TemplateMeta()
    const templateExportMeta = new TemplateExportMeta()
    const templateChoicesOptions = ref<ISelectValue[]>([])
    const formErrors = ref<FormErrors>(new FormErrors({}))
    let draggedItem: ISelectValue|null = null
    const isFrom = ref({ show: false, type: '' })
    const toggle = async (show: boolean = false, type: string = '') => {
      isFrom.value.show = show
      isFrom.value.type = type
    }
    const isLoading = ref<{ [code: string]: boolean }>({
      unloadTemplate: false,
      downloadTemplate: false,
      downloadGuide: false,
      exportFile: false,
      exportDocument: false
    })

    const isOpenCheckFormat = ref<boolean>(false)

    const formatsFile: { name: string, value: string }[] = [
      { name: '.csv', value: 'csv' },
      { name: '.txt', value: 'txt' }
    ]

    const checkedFormatFile = ref(formatsFile[0])

    const unusedParams = ref<ISelectValue[]>([])
    const isLoadingTabTemplate = ref(false)

    watch(() => isFrom.value.type, async (to) => {
      isLoadingTabTemplate.value = true

      if (to === 'loading') {
        templateChoicesOptions.value = (await axios.get(templateMeta.endpoint + 'fields_choices/?is_reusable=false')).data
        // Удалить строку после правок на бэке
        templateChoicesOptions.value = templateChoicesOptions.value.filter((item: ISelectValue) => !item.value.includes('pin_'))

        unusedParams.value = (await axios.get(templateMeta.endpoint + 'fields_choices/?is_reusable=true')).data
        if (!props.templateId) templateForm.value = new Template()
        else {
          selectTemplate(templateMeta.load(
            (await axios.get(templateMeta.endpoint + props.templateId)).data
          ))
        }
      }

      if (to === 'unloading') {
        if (props.templateId) templateChoicesOptions.value = ((await axios.get(templateExportMeta.endpoint + `fields_choices/?upload_template_id=${props.templateId}`)).data).fields
        else templateChoicesOptions.value = (await axios.get(templateExportMeta.endpoint + 'fields_choices/')).data

        if (!props.templateExportId) templateForm.value = new Template()
        else {
          selectTemplate(templateExportMeta.load(
            (await axios.get(templateExportMeta.endpoint + props.templateExportId)).data
          ))
        }
      }

      isLoadingTabTemplate.value = false
    })

    const isConfirmModal = ref({ show: false, type: '' })
    const toggleConfirm = (type: string) => {
      isConfirmModal.value.show = !isConfirmModal.value.show
      isConfirmModal.value.type = type
    }

    const returnFieldUUID = () => {
      const comparefields = ['pin_additional_title', 'pin_not_download', 'pin_hidden_title_attr']

      templateForm.value.fields.map(item => {
        const row = comparefields.findIndex(val => item.value.includes(val))

        if (row !== -1) {
          if (item.value.includes('--')) return item
          const res = item.value.split('_').slice(0, -1).join('_') + '--' + uuid()
          item.value = res
        }
        return item
      })
    }

    const submit = async (): Promise<boolean> => {
      const endpoint = isFrom.value.type === 'loading' ? templateMeta.endpoint : templateExportMeta.endpoint
      const updatedTemplate = isFrom.value.type === 'loading' ? 'template' : 'templateExport'

      if (templateForm.value.id) {
        const response = await axios.put(endpoint + templateForm.value.id, templateMeta.dump(templateForm.value))

        if (response.status === 200) {
          templateForm.value.id = response.data.id
          templateList.value = (await axios.get(endpoint)).data.results.map((item: Template) => templateMeta.load(item))

          props.updateTemplateInProject(response.data.id, updatedTemplate)
          return true
        }
        if (response.data.errors) {
          returnFieldUUID()

          formErrors.value = templateMeta.errors(response.data.errors)
          return false
        }
      } else {
        const response = await axios.post(endpoint, templateMeta.dump(templateForm.value))

        if (response.status === 201) {
          templateForm.value.id = response.data.id
          templateList.value = (await axios.get(endpoint)).data.results.map((item: Template) => templateMeta.load(item))

          props.updateTemplateInProject(response.data.id, updatedTemplate)
          return true
        }
        if (response.data.errors) {
          returnFieldUUID()

          formErrors.value = templateMeta.errors(response.data.errors)
          return false
        }
      }
      return false
    }

    const deleteTemplate = async () => {
      const updatedTemplate = isFrom.value.type === 'loading' ? 'template' : 'templateExport'
      const endpoint = isFrom.value.type === 'loading' ? templateMeta.endpoint : templateExportMeta.endpoint

      if (templateForm.value.id) {
        if (templateForm.value.id === props.templateId) await props.updateTemplateInProject(null, updatedTemplate)
        await axios.delete(endpoint + templateForm.value.id)
        templateChoicesOptions.value = (await axios.get(templateMeta.endpoint + 'fields_choices/?is_reusable=false')).data
        // Удалить строку после правок на бэке
        templateChoicesOptions.value = templateChoicesOptions.value.filter((item: ISelectValue) => !item.value.includes('pin_'))

        templateList.value = (await axios.get(templateMeta.endpoint)).data.results.map((item: Template) => templateMeta.load(item))

        templateForm.value = new Template()
      } else {
        templateForm.value = new Template()
        templateChoicesOptions.value = (await axios.get(templateMeta.endpoint + 'fields_choices/?is_reusable=false')).data
      }
      toggleConfirm('')
      toggle(false)
    }

    const onStartDrag = (e: DragEvent, choice: ISelectValue) => {
      if (e.dataTransfer) {
        e.dataTransfer.dropEffect = 'move'
        e.dataTransfer.effectAllowed = 'move'
        draggedItem = choice
      }
    }

    const selectTemplate = async (item: Template|null) => {
      const endpoint = isFrom.value.type === 'loading' ? templateMeta.endpoint : templateExportMeta.endpoint

      if (!item) {
        templateForm.value = new Template()
        templateChoicesOptions.value = isFrom.value.type === 'loading'
          ? (await axios.get(endpoint + 'fields_choices/?is_reusable=false')).data
          : ((await axios.get(endpoint + `fields_choices/?upload_template_id=${props.templateId}`)).data).fields

        return
      }

      if (!Object.keys(item).length) {
        templateForm.value = new Template()
        templateChoicesOptions.value = (await axios.get(endpoint + 'fields_choices/')).data
      } else {
        templateForm.value = item
        returnFieldUUID()

        templateChoicesOptions.value = isFrom.value.type === 'loading'
          ? (await axios.get(endpoint + 'fields_choices/?is_reusable=false')).data
          : ((await axios.get(endpoint + `fields_choices/?upload_template_id=${props.templateId}`)).data).fields

        if (isFrom.value.type === 'loading') {
          templateChoicesOptions.value = templateChoicesOptions.value.filter((item: ISelectValue) => !item.value.includes('pin_'))
          unusedParams.value = (await axios.get(endpoint + 'fields_choices/?is_reusable=true')).data
        }

        templateChoicesOptions.value = templateChoicesOptions.value.filter((item: ISelectValue) => {
          return !templateForm.value.fields.some((x: ISelectValue) => x.value === item.value)
        })
      }
    }

    const onDrop = (e: any, list: string) => {
      if (list === 'selected') replaceDragItem(e, templateForm.value.fields, templateChoicesOptions.value)
      if (list === 'options') replaceDragItem(e, templateChoicesOptions.value, templateForm.value.fields)

      draggedItem = null
    }

    const replaceOnDblcClick = (item: ISelectValue, target: 'selected'|'options') => {
      draggedItem = item
      if (target === 'selected') replaceDragItem({}, templateForm.value.fields, templateChoicesOptions.value)
      if (target === 'options') replaceDragItem({}, templateChoicesOptions.value, templateForm.value.fields)
    }

    const replaceDragItem = (e: any, items: ISelectValue[], secondItems: ISelectValue[]) => {
      if (!draggedItem) return

      const onDropItem = e.draggable ? items.find(item => item.name === e.outerText) : null

      if (items.indexOf(draggedItem) >= 0 && onDropItem) {
        items.splice(items.indexOf(draggedItem), 1)
        items.splice(items.indexOf(onDropItem), 0, draggedItem)
      }

      if (items.indexOf(draggedItem) < 0) {
        if (onDropItem) {
          items.splice(items.indexOf(onDropItem), 0, draggedItem)
          secondItems.splice(secondItems.indexOf(draggedItem), 1)
        } else {
          if (draggedItem.value.includes('pin_')) {
            secondItems.splice(secondItems.indexOf(draggedItem), 1)

            if (isFrom.value.type === 'unloading') {
              draggedItem.value = draggedItem.value.split('_').slice(0, -1).join('_') + '--' + uuid()
              items.push(draggedItem)
            }
          } else {
            secondItems.splice(secondItems.indexOf(draggedItem), 1)
            items.push(draggedItem)
          }
        }
      }
      draggedItem = null
    }

    const exportFile = async () => {
      if (!templateForm.value?.id) return

      const endpoint = isFrom.value.type === 'loading' ? templateMeta.endpoint : templateExportMeta.endpoint

      isLoading.value.exportFile = true
      try {
        const response = (await axios.post(endpoint + templateForm.value.id + '/export_to_file/')).data
        const celaryResult = await getCeleryTask(response.result_id)

        if (celaryResult) {
          window.open(celaryResult.data.result)
          mutations.pushNotification('Файл успешно выгружен')
        } else mutations.pushNotification('Ошибка выгрузки шаблона', true)
        isLoading.value.exportFile = false
      } catch (e) {
        mutations.pushNotification('Ошибка выгрузки шаблона', true)
        isLoading.value.exportFile = false
      }
    }

    const selaryHandler = async (celaryId: string, loader: string) => {
      const message = loader === 'downloadTemplate'
        ? 'Шаблон загрузки успешно загружен'
        : loader === 'unloadTemplate' ? 'Шаблон выгрузки успешно загружен' : 'Справочник успешно загружен'
      const errorMessage = loader === 'downloadTemplate'
        ? 'Ошибка загрузки шаблона'
        : loader === 'unloadTemplate' ? 'Ошибка загрузки шаблона выгрузки' : 'Ошибка загрузки справочника'
      try {
        let data = null
        if (loader === 'downloadGuide') {
          data = await getCeleryTask(celaryId, 3000, loader)
        } else {
          data = await getCeleryTask(celaryId)
        }
        mutations.pushNotification(message)
        return data
      } catch {
        mutations.pushNotification(errorMessage, true)
      } finally {
        isLoading.value[loader] = false
      }
    }

    const downloadGuide = async (files: FileList) => {
      if (!props.templateId || !props.projectId) return

      const formData = new FormData()
      formData.append('file', files[0])
      isLoading.value.downloadGuide = true
      const { data } = await axios.post(host + '/api/v1/file', formData)

      const response = (await axios.post(host + '/api/v1/project/import_products/', {
        file: data.id,
        project: props.projectId,
        template: props.templateId
      })).data

      await selaryHandler(response.result_id, 'downloadGuide')
    }

    const downloadTemplate = async (files: FileList) => {
      const formData = new FormData()
      formData.append('file', files[0])
      isLoading.value.downloadTemplate = true
      const { data } = await axios.post(host + '/api/v1/file', formData)

      const response = (await axios.post(host + '/api/v1/template/import_template', {
        file: data.id
      })).data

      const { result } = await selaryHandler(response.result_id, 'downloadTemplate')
      if (result.template_id) props.updateTemplateInProject(result.template_id, 'template')
    }

    const unloadTemplate = async (files: FileList) => {
      const formData = new FormData()
      formData.append('file', files[0])
      isLoading.value.unloadTemplate = true
      const { data } = await axios.post(host + '/api/v1/file', formData)

      const response = (await axios.post(host + '/api/v1/template_export/import_template/', {
        file: data.id
      })).data

      const { result } = await selaryHandler(response.result_id, 'unloadTemplate')
      if (result.template_id) props.updateTemplateInProject(result.template_id, 'template')
    }

    const exportDocument = async () => {
      const format = checkedFormatFile.value.value
      isLoading.value.exportDocument = true
      try {
        const { data } = await axios.post(projectMeta.endpoint + 'export_document/',
          {
            project: params.id,
            template: props.templateExportId,
            format: format
          }
        )

        const celaryResult = (await getCeleryTask(data.result_id)).data
        if (celaryResult && !celaryResult.result.errors) {
          const { file } = (await axios.get(host + '/api/v1/file/' + celaryResult.result)).data
          window.open(file)
          mutations.pushNotification('Документы успешно выгружены')
        } else mutations.pushNotification('Ошибка выгрузки документов', true)
        isLoading.value.exportDocument = false
      } catch (error) {
        isLoading.value.exportDocument = false
        mutations.pushNotification('Ошибка выгрузки документов', true)
      }
    }

    const checkFormatFile = () => {
      isOpenCheckFormat.value = !isOpenCheckFormat.value
    }

    // Управление модалкой по удалению справочника
    const isShowDeleteDirectoryModal = ref(false)

    function setIsShowDeleteDirectoryModal (newVal: boolean) {
      isShowDeleteDirectoryModal.value = newVal
    }
    //

    const selectUnusedParam = (unused: ISelectValue) => {
      templateForm.value.fields.push(unused)
    }

    return () => (
      <div class="layout">
        <p class="col-title">Работа с проектом</p>
        <div class="row">
          <div onClick={() => toggle(true, 'loading')} class="load-button">Настройка шаблонов</div>
          <div class="load-button" onClick={() => { setIsShowDeleteDirectoryModal(true) }}>Удалить справочник</div>
        </div>
        {isShowDeleteDirectoryModal.value && <DeleteDirectoryModal closeModal={() => { setIsShowDeleteDirectoryModal(false) }} projectId={props.projectId}/>}
        <div class="stat-col mt-3">
          <div class="row">
            <div class="col">
              <p class="col-title">Загрузка файлов</p>
              <div class="load-button">
                <input
                  class="hidden" type="file"
                  onChange={(e: Event) => downloadTemplate((e.target as HTMLFormElement).files)}
                />
                <ButtonLoader hidden={!isLoading.value.downloadTemplate}/>
                Шаблон загрузки
              </div>
              <div class="load-button progress">
                <input
                  class="hidden" type="file"
                  onChange={(e: Event) => downloadGuide((e.target as HTMLFormElement).files)}
                />
                <ProgressButton
                  text="Загрузить справочник"
                  callback={() => {}}
                  progressId="downloadGuide"
                  isLoading={isLoading.value.downloadGuide}
                />
              </div>
            </div>

            <div class="col" onPointerleave={() => { isOpenCheckFormat.value = false }}>
              <p class="col-title">Выгрузка файлов</p>
              <div class="load-button">
                <input
                  class="hidden" type="file"
                  onChange={(e: Event) => unloadTemplate((e.target as HTMLFormElement).files)}
                />
                <ButtonLoader hidden={!isLoading.value.unloadTemplate}/>
                Шаблон выгрузки
              </div>
              <div
                class="block-loads"
              >
                <div
                  class="load-button"
                  style={{
                    background: isOpenCheckFormat.value ? '#fff' : '#E4E5E8',
                    border: isOpenCheckFormat.value ? '1px solid #9DA6B6' : '',
                    borderRadius: isOpenCheckFormat.value ? '4px 0px 0px 0px' : '',
                    borderBottom: isOpenCheckFormat.value ? '' : ''
                  }}
                  onClick={exportDocument}
                >
                  <ButtonLoader hidden={!isLoading.value.exportDocument}/>
                  Выгрузить файл
                </div>
                <div
                  class={{ 'load-button': true, 'check-format-active': isOpenCheckFormat.value, 'check-format': !isOpenCheckFormat.value }}
                  style={{
                    background: isOpenCheckFormat.value ? '#fff' : '#E4E5E8',
                    border: isOpenCheckFormat.value ? '1px solid #9DA6B6' : '',
                    borderLeft: isOpenCheckFormat.value ? '' : '',
                    borderBottom: isOpenCheckFormat.value ? '' : '',
                    borderRadius: isOpenCheckFormat.value ? '0px 4px 0px 0px' : ''
                  }}
                  // onClick={checkFormatFile}
                  onPointerenter={() => { isOpenCheckFormat.value = true }}
                >
                </div>
                <div class="check-format-list" style={{ display: isOpenCheckFormat.value ? 'block' : 'none' }}>
                  <RadioButtonGroup
                    items={formatsFile as any}
                    activeElement={checkedFormatFile.value as any}
                    onValuChange={(v) => { checkedFormatFile.value = v }}
                  />
                </div>
              </div>

            </div>
          </div>
        </div>
        {isFrom.value.show
          ? <Modal
            type={isFrom.value.type}
            templateForm={templateForm}
            formErrors={formErrors}
            templateMeta={templateMeta}
            templateExportMeta={templateExportMeta}
            templateChoicesOptions={templateChoicesOptions}
            isLoading={isLoading}
            isConfirm={isConfirmModal.value.show}
            confirmType={isConfirmModal.value.type}
            toggle={toggle}
            toggleConfirm={toggleConfirm}
            selectTemplate={selectTemplate}
            exportFile={exportFile}
            submit={submit}
            deleteTemplate={deleteTemplate}
            onDrop={onDrop}
            onStartDrag={onStartDrag}
            replaceOnDblcClick={replaceOnDblcClick}
            unusedParams={unusedParams}
            selecteUnusedParam={selectUnusedParam}
            templateId={props.templateId}
            isLoadingTabTemplate={isLoadingTabTemplate.value}
          />
          : null
        }
      </div>
    )
  }
})
